.filter-items-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 4;
  background-color: #000000;
  top: 50px;
  padding: 1rem;
  justify-content: flex-start;
  gap: 1rem;
  right: 0;
  max-width: 250px;
  border-radius: 5px;
}

.filter-close-icon {
  padding: 0.3rem;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  cursor: pointer;
}

.filter-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filter-menu {
  list-style-type: none;
  display: flex;
  gap: 0.4rem;
  font-size: 0.9rem;
  flex-wrap: wrap;
}

.filter-section-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

@media screen and (max-width: 500px) {
  .filter-root {
    grid-template-columns: none;
  }
}
