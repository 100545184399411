.movie-details-root {
  margin: auto;
  max-width: 1300px;
}

.movie-details-section {
  padding: 0 1rem;
}

.movie-details-media-root {
  color: white;
  display: flex;
  align-items: baseline;
  gap: 1rem;
  margin-top: 2rem;
  flex-direction: column;
}

.movie-details-container {
  color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.movie-details-container-overlay {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  background: rgba(0, 0, 0, 0.7);
}
.movie-details-container-overlay-content-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.movie-details-poster-image {
  max-height: 500px;
  max-width: 384px;
  margin-left: 1rem;
}

.main-movie-image {
  width: 95vw;
}

.movie-text-info-container {
  padding: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.movie-detail-video-container-desktop {
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

iframe {
  border: none;
}

.movie-details-container-overlay h1 {
  padding: 0 1rem;
}

.movie-text-info-container h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.movie-details-wrapper {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1rem;
}

.movie-details-wrapper p {
  font-size: 1em;
}

.crew-name {
  font-size: 0.8em;
  color: rgba(180, 180, 180, 0.86);
}

.movie-item {
  color: rgba(180, 180, 180, 0.86);
  width: fit-content;
  border-radius: 0.2em;
}

.rating-wrapper {
  display: flex;
  gap: 0.5rem;
}

.movie-tagline {
  margin-bottom: 0.6rem;
  font-style: italic;
}

.overview-text,
.movie-tagline,
.release-year {
  color: rgba(180, 180, 180, 0.86);
}

.overview-title {
  margin-bottom: 0.5rem;
}

.overview-text {
  font-size: 1em;
  font-weight: 100;
}

.crew-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  flex-wrap: wrap;
  margin: 1rem 0;
  gap: 3rem;
}

.no-cast-text {
  color: #ffffff;
}

.crew-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.crew-wrapper p:first-child {
  font-weight: bold;
}

.stream-section {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.trailer-section {
  margin-bottom: 1rem;
}

.trailer-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.watch-service-container {
  display: flex;
  align-items: center;
  background-color: #313131;
  width: 200px;
  justify-content: space-evenly;
  gap: 5px;
  border-radius: 5px;
  padding: 0.5rem;
}

.watch-logo {
  font-size: 30px;
}

.streaming-logo {
  height: 50px;
  width: 50px;
  border-radius: 0.25rem;
}

.movie-cast-crew-numbers-container {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  position: relative;
}

.cast-crew-wrapper {
  max-width: 1300px;
}

.movie-details-title {
  color: white;
  margin-bottom: 1rem;
  position: relative;
}

.header::before {
  content: "";
  background: yellow;
  position: absolute;
  width: 3px;
  margin-left: -0.45rem;
  height: 100%;
  border-radius: 2px;
}

.cast-crew-container {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  overflow-y: scroll;
  gap: 10px;
  overflow-y: hidden;
}

.social-media-container {
  color: white;
  margin: 1rem 0;
}

.watch-options-header {
  margin-bottom: 0.5rem;
}

.cast-crew-item {
  min-width: 150px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 150px;
}

.cast-crew-item a {
  text-decoration: none;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.cast-crew-name {
  font-weight: bold;
  padding: 0.5rem 0 0.7rem 0;
}
.cast-crew-item h3 {
  font-size: 0.95em;
}

.cast-crew-item img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  border: 2px solid transparent;
}

.cast-crew-item img:hover {
  border: 2px solid hsl(0, 0%, 100%);
}

.cast-crew-item p:nth-child(3) {
  color: rgba(180, 180, 180, 0.86);
  font-size: 0.85em;
}

.movie-status-container {
  color: #fff;
  background: black;
  right: -24px;
}

.movie-status-wrapper {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 1rem;
}

.movie-status-container li p:first-child {
  font-weight: bold;
}

.movie-status-container li p:nth-child(2) {
  color: rgba(180, 180, 180, 0.86);
}

.homepage-link {
  width: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: white;
}

.homepage-icon {
  font-size: 25px;
}

.review-container {
  display: flex;
  flex-direction: column;
  color: white;
  margin-top: 2rem;
}

.review-parent-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.review-card-container-item {
  background-color: #1f1f1f;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.review-header {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.review-content {
  color: rgba(180, 180, 180, 0.86);
}

@media (max-width: 1270px) {
  .movie-details-container {
    flex-direction: column;
    background-position: center;
  }
}

@media (max-width: 800px) {
  .movie-details-root {
    margin: 0 1.5rem;
  }

  .movie-details-container-overlay h1 {
    padding: 0;
  }

  .movie-status-container li p {
    font-size: 0.8em;
  }

  .movie-details-section {
    padding: 0;
  }

  .movie-details-poster-image-trailer {
    display: none;
  }

  iframe {
    height: 330px;
  }

  .movie-details-container {
    background-image: none !important;
    margin: 0;
  }

  .movie-details-container-overlay {
    flex-direction: column;
    background: none;
    gap: 2rem;
  }

  .movie-text-info-container {
    padding: 0;
  }

  .movie-details-container-overlay img:first-child {
    max-width: 300px;
    align-self: center;
  }

  .movie-cast-crew-numbers-container {
    gap: 1rem;
    flex-direction: column-reverse;
  }

  .movie-status-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .movie-status-container {
    box-shadow: none;
    padding: 0;
    position: static;
  }

  .movie-details-wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 0;
  }

  .movie-status-wrapper li {
    flex-basis: 50%;
  }

  .cast-crew-wrapper {
    max-width: 1300px;
  }

  .review-card-container-item {
    width: 100%;
  }

  .movie-info {
    position: static;
  }

  .movie-text-info-container h1 {
    font-size: 1.5rem;
  }

  .movie-details-wrapper {
    font-size: 0.8em;
  }

  .crew-wrapper p:nth-child(1) {
    font-size: 0.8em;
  }
}

.trailer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.trailer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

.trailer-modal-content {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  height: auto;
  max-width: 800px;
  max-height: 80vh;
}

.iframe-container {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9px;
  background-color: black;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.close {
  cursor: pointer;
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

@media (max-width: 800px) {
  .review-parent-container{
    grid-template-columns: 1fr;
    justify-content: center;
  }

  .movie-details-section {
    padding: 0;
  }
}

@media (max-width: 450px) {
  .movie-details-container-overlay {
    gap: 0.5rem;
  }
  .review-parent-container {
    align-self: center;
  }

  .movie-details-section {
    padding: 0;
  }

  iframe {
    height: 173px;
  }

  .movie-details-wrapper {
    gap: 0.5rem;
    margin-bottom: 0;
  }
}
