.no-user-container {
  background: #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 361px;
  border-radius: 5px;
}

.no-profile-img {
  font-size: 50px;
  fill: #b5b5b5;
}
