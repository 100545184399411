.search-wrapper {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  width: 100%;
}

.search-input {
  flex: 1;
  border: none;
  background-color: transparent;
  color: #1a1a1a;
  font-size: 1rem;
  outline: none;
  padding-left: 0.7em;
  z-index: 1;
}

.search-input::placeholder {
  color: #1a1a1a;
}

.search-icon {
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}
