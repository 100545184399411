.just-watch-root {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.just-watch-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.just-watch-header h1 {
  color: gold;
  padding: 0;
}

.select-container {
  width: 250px;
  color: black;
}

.country-flag-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.just-watch-stream-container {
  display: flex;
  justify-content: space-between;
}

.stream-services-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stream-services-header {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.watch-service-list {
  overflow-y: scroll;
  display: flex;
  list-style-type: none;
  gap: 0.8rem;
}

.watch-service-list img {
  border-radius: 0.25rem;
}

