.pagination-container {
  color: white;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  font-size: 1em;
  margin-bottom: 1rem;
  width: fit-content;
  white-space: nowrap;
  margin: 0;
}

.next-page,
.back-page {
  font-size: 28px;
  border: 1px solid;
  border-radius: 5px;
  padding: 0.3rem;
}

.next-page:hover,
.back-page:hover {
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  color: #313131;
}