.movies-container {
  width: 100vw;
  padding: 1rem;
  color: white;
}

.filter-container {
  align-items: center;
  margin: 1rem 0;
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.page-title-primary {
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
}
