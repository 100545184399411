.no-image-container {
  background-color: #DBDBDB;
  height: 300px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.no-film-icon {
  fill: #B5B5B5;
  font-size: 25px;
  align-self: center;
}
