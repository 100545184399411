.person-container {
  color: white;
  gap: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.person-small-details-wrapper {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.person-wrapper {
  color: white;
  gap: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.person-credit-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.person-credit-wrapper {
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  -webkit-max-logical-width: min-content;
}

.person-image {
  width: 250px;
  max-height: 500px;
  min-width: 190px;
  border-radius: 5px;
}

.person-info-title {
  position: relative;
}

.no-person-image {
  min-width: 288px;
}

.person-biography-container {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 0;
}

.person-name {
  font-size: 2rem;
}

.person-bio-title {
  position: relative;
}

.biography-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.biography-container p {
  color: rgba(180, 180, 180, 086);
}

.person-biography-details {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
  white-space: nowrap;
}

.person-biography-details  div > p {
  color: rgba(180, 180, 180, 086);
}

.person-biography-details h3 {
  margin-bottom: 0.3em;
}

p {
  font-size: 1em;
}

.social-link-list {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  gap: 1.5rem;
  margin: 1rem 0;
}

.social-link-list svg {
  width: 30px;
  height: 30px;
}

.person-movie-credit-image-container {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 1.5rem;
}

.person-movie-credit-image {
  width: 221px;
  height: 330px;
  border-radius: 5px;
}

@media screen and (max-width: 1200px) {
  .person-container {
    flex-direction: column;
    margin: 1.5rem !important;
  }
}

@media screen and (max-width: 800px) {
  .person-biography-details {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .person-small-details-wrapper {
    flex-direction: column;
    gap: 0;
  }

  .person-image-container {
    display: flex;
    flex-direction: column;
  }

  .person-biography-details {
    grid-template-rows: 1fr;
  }

  .person-name {
    font-size: 1.5em;
  }

  .person-bio-title {
    font-size: 1.17em;
  }
}
