*,
::before,
::after {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: "Source Sans Pro",Arial,sans-serif;
}

body {
  background-color: black;
}

html {
  height: 100vh;
  width: 100vw;
}

img {
  max-width: 100%;
}

.button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

.button:hover {
  background-color: #2c2c2c;
}

.primary {
  background-color: #1a1a1a;
}

.button-icon {
  display: flex;
  gap: 0.5rem;
  font-size: 1rem;
  align-items: center;
}

.secondary {
  border: 2px solid #1a1a1a;
  background-color: transparent;
}

.tertiary {
  background-color: gold;
  color: black;
  border-left: none;
}

.tertiary:hover {
  color: white;
  background-color: gold;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as desired */
  z-index: 1;
}

@media  screen and (max-width: 400px) {
  h1 {
    font-size: 1rem;
  }

  .button {
    padding: 5px 10px;
    font-weight: none;
  }
  
  h2 {
    font-size: 0.9rem;
  }

  h3 {
    font-size: 0.75rem;
  }

  p {
    font-size: 0.7rem;
  }
}
