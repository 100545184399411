.poster-link {
  text-decoration: none;
  color: white;
}

.film-container img {
  cursor: pointer;
  border: 2px solid transparent;
  width: 100%;
}

.film-container img:hover {
  border: 2px solid hsl(0, 0%, 100%);
}

.movie-title {
  position: absolute;
  top: -1rem;
  color: hsl(0, 0%, 100%);
}

.movie-image-container {
  /* background-color: #1A1A1A; */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  /* min-height: 295px; */
  min-height: 151px;
}

.movie-image-review-container {
  position: relative;
}


.movie-image-container img {
  border-radius: 5px;
}

.movie-image-review-container svg {
  left: 9px;
  position: absolute;
  bottom: -3px;
}

.poster-item-detail {
  padding-left: 0.4rem;
}

.poster-rating {
  color: white;
  position: absolute;
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  height: 2.5rem;
  width: 3rem;
  padding: 0.5rem;
}

.svg-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-text {
  color: white;
  font-weight: bold;
  font-size: 14px;
}

.svg-text-small {
  font-size: 8px;
}
