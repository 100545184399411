/* .people-root {
  margin: auto ;
  max
} */

.people-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1rem 0;
}

#people-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
  gap: 1rem;
}

@media (max-width: 480px) {
  .people-filter-container {
    flex-direction: column;
  }


  #people-container {
    gap: 1rem;
    place-items: center;
  }
}
