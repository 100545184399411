.header-root {
  color: white;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  list-style-type: none;
}

.header-title {
  text-transform: capitalize;
  cursor: pointer;
  padding-bottom: 0.3rem;
}

.header-title span {
  color: grey;
  margin-left: 0.4rem;
}

.selected {
  border-bottom: 3px solid gold;
}

.selected-media-container {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  list-style-type: none;
  max-width: 100%;
}
.backdrops {
  display: flex;
  width: 533px;
  height: 300px;
}

.backdrops img {
  width: 533px;
  min-width: 533px;
  height: 300px;
  min-height: 300px;
}

.posters img {
  min-width: 200px;
  min-height: 300px;
  max-width: 200px;
}

.logos img {
  min-width: 200px;
  min-height: 130px;
}
