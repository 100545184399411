.search-result-root {
  color: #ffffff;
  margin: 0 11rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-item-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-item-container {
  background-color: #1f1f1f;
  display: flex;
  height: 150px;
  border-radius: 5px;
}

.search-item-container img {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.search-item-text-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1rem;
}

.search-icon {
  fill: #000000;
}

@media screen and (max-width: 800px) {
  .search-result-root {
    margin: 0 1.5rem;
  }
}