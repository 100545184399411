.home-root {
  display: flex;
  flex-direction: column;
  /* height: calc(100vh - 62px); */
  color: #ffffff;
  justify-content: space-evenly;
  gap: 1.5rem;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--zIndex);
  background-color: #000000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: var(--header-opacity);
  transition: opacity 1s ease-in-out;
  justify-content: center;
}

.text-container h1 {
  margin: 0;
  font-size: 5em;
  color: rgba(225, 225, 225, 0.01);
  background-image: url("../../assets/moviebackground.jpg");
  background-repeat: repeat;
  -webkit-background-clip: text;
  animation: starPatern 10s ease-in-out infinite;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
}

@keyframes starPatern {
  0%,
  100% {
    background-position: center center;
  }
  20% {
    background-position: left top;
  }
  40% {
    background-position: right top;
  }
  60% {
    background-position: right bottom;
  }
  80% {
    background-position: left bottom;
  }
}

.container {
  max-width: 1300px;
  margin: auto;
  padding: 0 1rem;
}

.home-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.subheader {
  font-size: 1em;
  line-height: 1.5;
}

.home-container > div:nth-child(1) {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.home-header-container {
  height: 48vh;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.home-header-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  background-image: linear-gradient(
    to right,
    rgba(32, 11, 11, 0.6) calc((50vw - 170px) - 340px),
    rgba(31.5, 10.5, 10.5, 0.84) 50%,
    rgba(31.5, 10.5, 10.5, 0.84) 100%
  );
}

.home-subtitle {
  position: relative;
  margin-left: 0.5rem;
}

.home-background-container {
  display: flex;
  gap: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 3rem 0;
  color: black;
  flex-direction: column;
  overflow-x: scroll;
}

.home-background {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
  list-style-type: none;
}

.home-container .button-group-container button:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.home-container .button-group-container button:nth-child(2) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.home-trailer-video {
  border: none;
  min-width: 300px;
  height: calc(300px / 1.78);
}

@media (max-width: 700px) {
  .home-container > div:nth-child(1) {
    gap: 0.3rem;
  }
}
