.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000000;
  color: #ffffff;
}

.error-message {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.error-link {
  color: #ffffff;
  font-size: 1.2rem;
}
