.nav-container {
  background-color: #000000;
  position: sticky;
  top: 0;
  z-index: 6;
  width: 100%;
  max-width: 1300px;
  margin: auto;
}

.nav-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  gap: 2rem;
  color: white;
}

.nav-logo-container {
  display: inline-block;
  vertical-align: middle;
}

.movie-spot-title {
  display: inline-block;
  margin-right: 10px;
  border-block-color: gold;
  border: gold;
  border-bottom-style: solid;
}

.nav-wrapper h3,
h4 {
  color: hsl(0, 0%, 100%);
  background-color: transparent;
}

.movie-spot-logo {
  width: 30px;
  height: 30px;
  color: gold;
  vertical-align: middle;
}

.category-wrapper {
  position: relative;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  background-color: white;
  width: auto;
  border-radius: 0.25rem;
  border: 1px solid #f0e2e2;
  white-space: nowrap;
  z-index: 5;
}

.dropdown-menu ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 0.5rem;
  font-size: 1.3em;
  padding: 0.5rem 1rem;
  font-weight: 100;
  color: black;
}

.dropdown-menu li a {
  color: black;
  font-size: 0.8em;
}

.dropdown-menu li:hover {
  font-weight: 400;
}

.link {
  color: hsl(0, 0%, 100%);
  text-decoration: none;
  background-color: transparent;
}

.link:hover {
  text-decoration: solid;
  cursor: pointer;
  fill: hsl(0, 0%, 100%);
}

.icon {
  color: hsl(0, 0%, 100%);
  background-color: transparent;
  font-size: 1.5rem;
}

.nav-search {
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.nav-search-container {
  position: absolute;
  top: 63px;
  width: 96%;
}

@media (max-width: 767px) {
  .nav-wrapper {
    font-size: 0.67em;
  }
  .movie-spot-logo {
    display: none;
  }

  .home-header-content {
    padding: 1rem;
  }

  .subheader{
    font-weight: 300;
  }

}